import { createStore } from "vuex";
import chat from "./app/chat";
import email from "./app/email";
import kanban from "./app/kanban";
import project from "./app/project";
import apptodo from "./app/todo";
import Cookie from 'js-cookie';
import { useToast } from "vue-toastification";
export default createStore({
  state: {
    sidebarCollasp: false,
    sidebarHidden: false,
    mobielSidebar: false,
    semidark: false,
    semiDarkTheme: "semi-light",
    isDark: false,
    user: {
      id: 0,
      uuid: null,
      name: null,
      email: null,
      email_verified_at: null,
      created_at: null,
      updated_at: null,
      nickname: null,
      image: null,
      delete_account: 0,
      text_delete_account: null,
      payment_id: null,
      phone: null,
      activation_token: null,
      active: 0,
      awards: [],
      sales: [],
      payment_methods: [
        {
          id: 0,
          name_method: null,
          name_user: null,
          type_key: null,
          key: null,
          api_token: null,
          status: 0,
          created_at: null,
          updated_at: null,
          user_id: 0
        }
      ],
      customizations: [
        {
          id: 0,
          image: null,
          pixel_meta: null,
          tag_google: null,
          user_id: 0,
          created_at: null,
          updated_at: null
        }
      ],
      social_medias: [],
      sites: [
        {
          id: 0,
          uuid: null,
          title: null,
        }
      ]
    },
    token: '',
    skin: "default",
    theme: "light",
    isOpenSettings: false,
    cWidth: "full",
    menuLayout: "horizontal",
    navbarType: "floating",
    footerType: "hidden",
    chartColors: {
      title: "red",
    },
  },
  getters: {
    theme: (state) => state.theme,
    skin: (state) => state.skin
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setSidebarCollasp(state) {
      state.sidebarCollasp = !state.sidebarCollasp;
    },

    toogleDark(state) {
      state.isDark = !state.isDark;


      if (!state.isDark) {
        document.body.classList.remove("dark");
        document.body.classList.add("light");
        localStorage.setItem("theme", "light");
      } else {
        document.body.classList.remove("light");
        document.body.classList.add("dark");
        localStorage.setItem("theme", "dark");
      }

    },

    toggleSettings(state) {
      state.isOpenSettings = !state.isOpenSettings;
    },
    toggleMsidebar(state) {
      state.mobielSidebar = !state.mobielSidebar;
    },
    toggleSemiDark(state) {
      state.semidark = !state.semidark;
      state.semiDarkTheme = state.semidark ? "semi-dark" : "semi-light";
      document.body.classList.toggle(state.semiDarkTheme);
      localStorage.setItem("semiDark", state.semidark);
    },
  },
  actions: {
    async getUserByUuid({ commit }) {
      try {
        const toast = useToast()
        const user_id = Cookie.get('_user_id');
        const token = Cookie.get('_access_token');
  /*
        // Criar o backdrop
        const backdrop = document.createElement('div');
        backdrop.className = 'backdrop';
  
        // Criar o contêiner
        const container = document.createElement('div');
        container.className = 'container_backdrop';  // Adicione a classe container
  
        // Criar o spinner
        const spinner = document.createElement('div');
        spinner.className = 'spinner';
  
        // Criar o span
        const span = document.createElement('span');
        span.innerText = 'Carregando seus dados, aguarde ...';
  
        // Adicionar o spinner e o span ao contêiner
        container.appendChild(spinner);
        container.appendChild(span);
  
        // Adicionar o contêiner ao backdrop
        backdrop.appendChild(container);
        // Adicionar o backdrop ao body
        document.body.appendChild(backdrop);*/
  
        const response = await fetch(
          process.env.VUE_APP_API_BASE_URL + '/user/uuid/' + user_id, 
          {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          }
        );
  
        // Remover o backdrop após a requisição ser concluída
       // backdrop.remove();
  
        if (response.status === 401) {
          toast.error('Seu token não é válido, faça login novamente.', {
            timeout: 2000,
          });
          return next('/');
        }
  
        const data = await response.json();
  
        if (!data.message) {
          /*if(!data[0].plan_id){
            toast.error('Assine um plano para continuar.', {
              timeout: 2000,
            });
            window.location.href = window.location.origin;
          }else{*/
            commit('setUser', data[0]);
            commit('setToken', token);
         // }
        }else{
          /*toast.error('Assine um plano para continuar.', {
            timeout: 2000,
          });
          window.location.href = window.location.origin;*/
        }
      } catch (error) {
        console.error('Error fetching user by UUID:', error);
      }
    },
    // toogleDark
    toogleDark({ commit }) {
      commit("toogleDark");
    },
    // toggleSettings
    toggleSettings({ commit }) {
      commit("toggleSettings");
    },
    // setSidebarCollasp
    setSidebarCollasp({ commit }) {
      commit("setSidebarCollasp");
    },
    // toggleMsidebar
    toggleMsidebar({ commit }) {
      commit("toggleMsidebar");
    },
    toggleSemiDark({ commit }) {
      commit("toggleSemiDark");
    },

    // setTheme
  },
  modules: {
    apptodo,
    project,
    kanban,
    email,
    chat,
  },
});
