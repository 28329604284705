<template>
  <router-view />
</template>

<style>

main{
  max-width: 1300px;
    margin: auto;
}
.backdrop {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  /* Fundo branco com opacidade */
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #0f172a;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Estilo para centralizar o contêiner */
.container_backdrop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 1000000;
}
/*
@media (min-width: 500px) {
  .content-wrapper {
    margin: 0 40px;
  }
}*/</style>
