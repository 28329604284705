import { createRouter, createWebHistory } from "vue-router";

import Guard from '../services/middleware.vue'
import store from '@/store'; // Importa a store para ter acesso ao estado
const routes = [
  /*{
    path: '/maintenance', // Pode ser qualquer caminho que desejar
    name: 'Maintenance',
    component: () => import("@/views/error.vue"),
  },*/
  {
    path: '/load', // Pode ser qualquer caminho que desejar
    name: 'Carregando Informações',
    component: () => import("@/views/load.vue"),
  },
  {
    path: '/thank-you/:uuid', // Pode ser qualquer caminho que desejar
    name: 'Obrigado pela assinatura!',
    component: () => import("@/views/checkout/thank-you.vue"),
  },
  {
    path: '/plans', // Pode ser qualquer caminho que desejar
    name: 'Planos e Preços',
    component: () => import("@/views/plans.vue"),
  },
  {
    path: "/",
    name: "Login",
    component: () => import("@/views/auth/login/index.vue"),
  },
  {
    path: "/register",
    name: "Cadastro",
    component: () => import("@/views/auth/register/index.vue"),
  },
  {
    path: "/forgot",
    name: "Esqueceu senha",
    component: () => import("@/views/auth/forgot-password.vue"),
  },
  {
    path: "/reset-password/:id",
    name: "Recuperar senha",
    component: () => import("@/views/auth/reset-password.vue"),
  },
  {
    path: "/active/account/:token",
    name: "Ativar Conta",
    component: () => import("@/views/auth/active-account.vue"),
  },
  {
    path: "/app",
    name: "Dashboard",
    redirect: "/app/home",
    component: () => import("@/Layout/index.vue"),
    beforeEnter: Guard.auth,
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/views/home/dashboard.vue"),
      },
      {
        path: "site/:uuid",
        name: "Site",
        component: () => import("@/views/sites/handle/index.vue"),
        children:[
          {
            path: "", // Rota vazia que vai redirecionar para "account"
            redirect: { name: "Detalhes" }
          },
          {
            path: "details",
            name: "Detalhes",
            component: () => import("@/views/sites/handle/details.vue"),
          },
          {
            path: "posts",
            name: "Posts",
            component: () => import("@/views/sites/handle/posts.vue"),
          },
          {
            path: "webstories",
            name: "Web stories",
            component: () => import("@/views/sites/handle/webstories.vue"),
          },
          {
            path: "categorias",
            name: "Categorias",
            component: () => import("@/views/sites/handle/categories.vue"),
          },
        ]
      },
      {
        path: "settings",
        name: "Ajustes",
        component: () => import("@/views/settings/settings.vue"),
        children:[
          {
            path: "", // Rota vazia que vai redirecionar para "account"
            redirect: { name: "Conta" }
          },
          {
            path: "account",
            name: "Conta",
            component: () => import("@/views/settings/account.vue"),
          },
          {
            path: "integrations",
            name: "Integrações",
            component: () => import("@/views/settings/integrations.vue"),
          },
          {
            path: "plan",
            name: "Plano",
            component: () => import("@/views/settings/plan.vue"),
          }
        ]
      },
      {
        path: "create/site",
        name: "Adicionado Site",
        component: () => import("@/views/sites/create.vue")
      },
      {
        path: "build/posts/site/:id",
        name: "Construindo Posts para o Site",
        component: () => import("@/views/sites/build.vue")
      },
      {
        path: "update/site/:id",
        name: "Atualizando Site",
        component: () => import("@/views/sites/update.vue")
      },
      {
        path: '/steps', // Pode ser qualquer caminho que desejar
        name: 'Onboarding',
        component: () => import("@/views/steps/index.vue"),
      }
    ],
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});
router.beforeEach(async (to, from, next) => {
  const routePath = to.path;
  let pageTitle = "IACOM";
/*
  // Verifique se o usuário já foi carregado
  let user = null;

  if (!user) {
    // Se o usuário não foi carregado, talvez seja necessário carregar os dados aqui
    try {
      await store.dispatch("getUserByUuid"); // Supondo que haja uma ação para carregar o usuário
      user = store.state.user;
    } catch (error) {
      console.error("Erro ao carregar o usuário:", error);
      next(false); // Impede a navegação em caso de erro
      return;
    }
  }

  // Verifique se estamos em uma das rotas específicas
  if (
    routePath.startsWith("/app/site/") &&
    (routePath.endsWith("/details") || routePath.endsWith("/posts") || routePath.endsWith("/webstories") || routePath.endsWith("/categorias"))
  ) {
    const uuid = routePath.split("/app/site/")[1].split("/")[0];
    let site = user.sites.find(site => site.uuid == uuid);

    if (site) {
      let subRouteTitle = "";
      if (routePath.endsWith("/details")) {
        subRouteTitle = "Detalhes de";
      } else if (routePath.endsWith("/posts")) {
        subRouteTitle = "Posts de";
      } else if (routePath.endsWith("/webstories")) {
        subRouteTitle = "Web stories de";
      }else if (routePath.endsWith("/categorias")) {
        subRouteTitle = "Categorias de";
      }

      pageTitle = `${subRouteTitle} ${site.title} | IACOM`;
    }
  }else if(routePath.startsWith("/app/update/site/")){ 
    const uuid = routePath.split("/app/update/site/")[1];
    let site = user.sites.find(site => site.uuid == uuid);

    if (site) {
      let subRouteTitle = "Atualizando site";
      pageTitle = `${subRouteTitle} ${site.title} | IACOM`;
    }
  } else {*/
    // Título padrão para outras rotas
    let titleText = to.name || "Página";

    if(titleText == 'home'){
      titleText = 'Sites'
    }
   // pageTitle = `${titleText} ${user.name} | IACOM`;
   pageTitle = `${titleText} | IACOM`;
 // }

  document.title = pageTitle;
  next();
});



router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
